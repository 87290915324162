<template>
  <el-dialog v-model="dialogVisible" :title="title" :show-close="false" :close-on-click-modal="false"
    :close-on-press-escape="false" width="500px" center>
    <el-form ref="elForm" :model="form" status-icon :rules="rules" label-width="120px">
      <el-form-item prop="sentry_id">
        <el-select v-model="form.sentry_id" placeholder="请选择活动区域">
          <el-option v-for="item in sentryList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button v-if="close" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import * as API_index from "@/api/index";

export default {
  name: '',
  components: {},
  props: {
    title: {
      type: [String, Number],
      default: ''
    },
    close: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        sentry_id: '',
      },
      rules: {
        sentry_id: [
          {
            required: true,
            message: '请选择岗亭',
          },
        ],
      },
      sentryList: [],
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    init() {
      let form = {
        park_id: this.getStore('park_id')
      }
      API_index.getParkSentry(form).then(res => {
        if (res.length > 0) {
          this.sentryList = res
          this.setStore('sentryState', true)
          this.dialogVisible = true
        } else {
          this.setStore('sentry_id', 0)
          this.$router.replace('/')
        }
      })
    },
    submit() {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          this.removeStore('sentryState')
          this.setStore('sentry_id', this.form.sentry_id)
          this.dialogVisible = false
          this.$emit('confirm', true)
          this.dutyClick(this.form.sentry_id)
          this.form.sentry_id = ''
          this.$router.replace('/')
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    dutyClick() {
      let form = {
        sentry_id: this.getStore('sentry_id'),
        park_id: this.getStore('park_id'),
        click: 1,
      }
      API_index.postDutyClick(form).then(() => { })
    },
  },
}
</script>
<style lang="scss" scoped></style>