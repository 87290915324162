import { createStore } from 'vuex'
import { clearStore } from '@/utils/storage'
import router from '../router'
import * as API_index from "@/api/index";

export default createStore({
  state: {
    mqtt: ''
  },
  mutations: {
    SET_MQTT(state, data) {
      state.mqtt = data
    },

  },
  actions: {
    async getMqtt({ commit }, form) {
      await API_index.getMqtt(form).then(res => {
        commit('SET_MQTT', res)
      })
    },
    loginOut() {
      clearStore()
      router.replace('/login')
    }
  },
  modules: {
  }
})
