<template>
  <el-dialog title="预览图" v-model="dialogVisible" width="900px" center :before-close="handleClose">
    <div class="box-img">
      <el-image ref="elImage" :id="id" :src="url+'?x-oss-process=image/resize,h_800,w_800'" :zoom-rate="1.2" :max-scale="7"
        :min-scale="0.2" :previewSrcList="[url]" fit="cover" @close="dialogVisible=false" />
      <!-- <img :src="url+'?x-oss-process=image/resize,h_800,w_800'" /> -->
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>

export default {
  name: 'Home',
  data() {
    return {
      id: '',
      dialogVisible: false,
      url: ''
    }
  },
  created() {
  },
  methods: {
    init(url) {
      this.id = new Date().getTime()
      this.url = url
      this.dialogVisible = true
      // this.$nextTick(() => {
      //   setTimeout(() => {
      //     document.getElementById(this.id).click()
      //   }, 300);
      // })
    },

  }
}
</script>
<style lang="scss" scoped>
.box-img {
  text-align: center;
  :deep(img) {
    width: auto;
    max-height: 50vh;
    &.el-image__inner {
      cursor: zoom-in;
    }
  }
}
</style>

