<template>
  <div class="login flex">
    <div class="login-left">
      <div class="login-title">智慧停车云岗亭</div>
      <el-form ref="elForm" :model="form" status-icon :rules="rules" label-width="120px" label-position="top"
        @keyup.enter="submit">
        <el-form-item label="账号" prop="username">
          <el-input v-model="form.username" type="text" placeholder="请输入账号" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input v-model="form.password" type="password" placeholder="请输入密码" autocomplete="off"></el-input>
        </el-form-item>

        <el-form-item>
          <el-button type="primary" @click="submit">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="login-bg"></div>
    <dialogSentry ref="dialogSentry" title="请选择值班岗亭" :close="false" />
  </div>
</template>

<script>
import dialogSentry from '@/views/login/dialog-sentry'
import * as API_login from "@/api/login";


export default {
  name: '',
  components: {
    dialogSentry
  },
  data() {
    return {
      form: {
        username: '',
        password: '',
      },
      rules: {
        username: [
          {
            required: true,
            message: '请输入帐号',
          },
        ],
        password: [
          {
            required: true,
            message: '请输入密码',
          },
        ],
      }
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    submit() {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          API_login.postUserLogin(this.form).then(res => {
            console.log(res)
            this.setStore('user', res)
            this.setStore('token', res.token)
            if (res.park && res.park[0].id) {
              this.setStore('park_id', res.park && res.park[0].id)
            }
            this.$refs.dialogSentry.init()
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },

  },
}
</script>
<style lang="scss" scoped>
.login {
  position: absolute;
  width: 900px;
  height: 522px;
  left: 0;
  right: 0;
  top: calc((100vh - 522px) / 2);
  margin: auto;

  .login-bg {
    width: 530px;
    height: 100%;
    background: url(~@/static/images/login-bg.jpg) center center no-repeat;
    background-size: cover;
  }
  .login-left {
    width: 370px;
    padding: 65px;
    box-sizing: border-box;
    height: 100%;
    background-color: #fff;
    .login-title {
      color: $primary;
      font-size: 24px;
      font-weight: bolder;
      margin-bottom: 30px;
    }
    :deep(.el-form-item__label) {
      height: 40px;
      color: $primary;
    }
    :deep(input) {
      background-color: #f1f8fe;
    }
    .el-button {
      width: 100%;
      margin-top: 20px;
      border-radius: 0;
    }
  }
}
</style>