<template>
  <el-dialog custom-class="el-dialog-box" v-model="dialogVisible" :close-on-click-modal="false" width="710px" center>
    <div class="handover-data">
      <div class="handover-money flex flex-jc">
        <div>
          <label>{{statisticsData.sum}}元</label>
          <p>现金金额</p>
        </div>
        <div>
          <label>{{statisticsData.count}}笔</label>
          <p>现金笔数</p>
        </div>
      </div>
      <div class="handover-number flex flex-jsb">
        <div v-for="(item,index) in statisticsData.list" :key="index">
          <label>{{item.count}}</label>
          <p>{{item.name}}</p>
        </div>
      </div>
    </div>
    <div class="handover-box">
      <div class="handover-title">是否要交班</div>
      <div class="handover-btton flex flex-jc">
        <el-button plain @click="dialogVisible=false">取消</el-button>
        <el-button type="primary" @click="confirm">确定</el-button>
      </div>
    </div>
  </el-dialog>
</template>

<script>
import * as API_index from "@/api/index";
import * as API_login from "@/api/login";

export default {
  name: '',
  components: {},
  props: {
    title: {
      type: [String, Number],
      default: ''
    },
    close: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      statisticsData: {},  //出入场类型

    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    init() {
      this.dialogVisible = true
      this.getData()
    },

    getData() {
      this.loading = true
      API_index.getHome({ park_id: this.getStore('park_id') }).then(res => {
        this.loading = false
        this.statisticsData = res.tab1
      })

    },
    confirm() {
      let form = {
        sentry_id: this.getStore('sentry_id') || '',
        park_id: this.getStore('park_id'),
        click: 2,
      }
      API_index.postDutyClick(form).then(() => {
        API_login.postUserLoginout().then(() => {
          this.clearStore()
          this.$message({
            type: 'success',
            message: '退出登录成功!',
          })
          setTimeout(() => {
            this.$router.replace('/login')
          }, 1000)

        })
      })

    },
  },
}
</script>
<style lang="scss" scoped>
.handover-data {
  height: 324px;
  color: #fff;
  background-color: $primary;
  .handover-money {
    text-align: center;
    padding: 50px;
    & > div {
      width: 50%;
      &:first-child {
        border-right: 1px solid #b9daf6;
      }
    }
    label {
      font-size: 36px;
    }
    p {
      font-size: 20px;
      color: #b9daf6;
      text-align: center;
    }
  }
  .handover-number {
    padding: 30px 50px;
    text-align: center;
    label {
      font-size: 24px;
    }
    p {
      font-size: 20px;
      color: #b9daf6;
      text-align: center;
    }
  }
}
.handover-box {
  height: 220px;
  text-align: center;
  .handover-title {
    font-size: 27px;
    padding: 40px 0;
  }
  .handover-btton {
    padding: 0 50px;
  }
  .el-button {
    width: 240px;
    height: 50px;
    margin: auto 30px;
  }
}
</style>
