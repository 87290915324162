import { getRequest, postRequest, putRequest, deleteRequest } from '@/api/request'

// mqtt
export const getMqtt = (params) => {
  return getRequest('park_pc/park/mqtt', params);
};

// 岗亭列表
export const getParkSentry = (params) => {
  return getRequest('park_pc/park/sentry', params);
};

// 车场通道
export const getParkGate = (params) => {
  return getRequest('park_pc/park/gate', params);
};

// 开关闸
export const postParkGateAction = (params) => {
  return postRequest('park_pc/park/gate_action', params);
};

// 通道-触发识别
export const postGateTrigger = (params) => {
  return postRequest('park_pc/park/gate_trigger', params);
};

// 现金放行
export const getRecordAdminPrePay = (params) => {
  return getRequest('park_pc/record/admin_pre_pay', params);
};

// 不予处理
export const getRecordUndo = (params) => {
  return postRequest('park_pc/record/undo', params);
};



// 免费放行
export const postRecordManualFreeOut = (params) => {
  return postRequest('park_pc/record/manual_free_out', params);
};
// 免费放行原因
export const getRecordFreeOut = (params) => {
  return getRequest('park_pc/record/free_reason', params);
};



// 手动入场
export const postRecordManualIn = (params) => {
  return postRequest('park_pc/record/manual_in', params);
};

// 手动出场
export const postRecordManualOut = (params) => {
  return postRequest('park_pc/record/manual_out', params);
};


// 车辆入场记录
export const getRecordInRecord = (params) => {
  return getRequest('park_pc/record/in_record', params);
};

// 车辆入场记录
export const getRecordEntRecord = (params) => {
  return getRequest('park_pc/record/ent_record', params);
};

// 车辆出场记录
export const getRecordDepRecord = (params) => {
  return getRequest('park_pc/record/dep_record', params);
};

// 禁止通行记录
export const getRecordForbidden = (params) => {
  return getRequest('park_pc/record/forbidden', params);
};
// 待处理记录
export const getRecordUnpay = (params) => {
  return getRequest('park_pc/record/unpay', params);
};


// 在场车辆详情
export const getRecordRecordDetail = (params) => {
  return getRequest('park_pc/record/record_detail', params);
};

// 交班统计数据
export const getHome = (params) => {
  return getRequest('park_pc/home', params);
};


// 计费规则
export const getParkPropRule = (params) => {
  return getRequest('park_pc/park/prop_rule', params);
};

// 在场车辆车牌修改
export const postRecordRecordUpdate = (params) => {
  return postRequest('park_pc/record/record_update', params);
};

// 关联车辆
export const getRecordRelation = (params) => {
  return getRequest('park_pc/record/relation', params);
};

// 值班打卡
export const postDutyClick = (params) => {
  return postRequest('park_pc/duty/click', params);
};
