<template>
  <el-dialog v-model="dialogVisible" :title="title" :show-close="false" :close-on-click-modal="false"
    :close-on-press-escape="false" width="500px" center>
    <el-form ref="elForm" :model="form" status-icon :rules="rules" label-width="120px" @keyup.enter="submit">
      <el-form-item label="账号">
        {{user.username}}
      </el-form-item>
      <el-form-item label="密码" prop="password">
        <el-input type="password" v-model="form.password" placeholder="请输入密码" style="width:220px"></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button v-if="close" @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import * as API_login from "@/api/login";

export default {
  name: '',
  components: {},
  props: {
    title: {
      type: [String, Number],
      default: ''
    },
    close: {
      type: Boolean,
      default: true
    },
  },
  data() {
    return {
      dialogVisible: false,
      form: {
        park_id: this.getStore('park_id'),
        password: '',
      },
      rules: {
        password: [
          {
            required: true,
            message: '请输入密码',
          },
        ],
      },
      user: JSON.parse(this.getStore('user')).user,
    }
  },
  created() {
  },
  mounted() {
  },
  methods: {
    init() {
      this.dialogVisible = true
    },
    submit() {
      this.$refs.elForm.validate((valid) => {
        if (valid) {
          API_login.postUserUnlock(this.form).then(() => {
            this.dialogVisible = false
            this.removeStore('lockState')
            this.$emit('confirm', true)
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
</style>