import { createRouter, createWebHashHistory } from 'vue-router'

const _import = file => () => Promise.resolve(require(`@/views/${file}`).default)

const routes = [
  {
    path: '/404',
    component: _import('404'),
    meta: {
      title: '404'
    }
  },
  {
    path: '/',
    component: _import('home'),
    meta: {
      title: '智慧停车云岗亭',
    }
  },
  {
    path: '/login',
    component: _import('login/login'),
    meta: {
      title: 'login',
      keepAlive: true,
    }
  },
  // { path: '*', redirect: '/404' }

]


const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes: [...routes]
})

export default router
