import { getRequest, postRequest, putRequest, deleteRequest } from '@/api/request'

// 登录
export const postUserLogin = (params) => {
  return postRequest('park_pc/user/login', params);
};
// 退出登录
export const postUserLoginout = (params) => {
  return postRequest('park_pc/user/loginout', params);
};


// 车场人员当前状态信息
export const getUserInfo = (params) => {
  return getRequest('park_pc/user/info', params);
};

// 车场人员锁屏
export const getUserLock = (params) => {
  return getRequest('park_pc/user/lock', params);
};

// 车场人员解锁屏幕
export const postUserUnlock = (params) => {
  return postRequest('park_pc/user/unlock', params);
};
